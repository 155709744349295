// 职位排序 (纯净列表、无tab列表)
const sortTypes =  [
  {type: 0, title: '默认'},
  {type: 1, title: '离我近'},
  {type: 2, title: '最新'},
  {type: 3, title: '薪资高'},
  {type: 4, title: '人气'},
  {type: 5, title: '最新人气'},
  {type: 6, title: '猜你喜欢'},
  {type: 7, title: '精选'},
  {type: 8, title: '随机'},
  {type: 9, title: '轮询'},
]

// 筛选类型
const screenTypes = [
  {type: 1, title: '岗位类型'},
  {type: 2, title: '地区'},
  {type: 3, title: '结算方式'},
  {type: 4, title: '性别要求'},
  {type: 5, title: '工作周期'},
  // {type: 6, title: '薪资范围'},
  // {type: 7, title: '企业'},
  // {type: 8, title: '岗位一级类目'},
  // {type: 9, title: 'cpc'},
]

// 结算方式
const settleTypes = [
  {title: '不限', type: -1},
  {title: '面议', type: 0},
  {title: '日结', type: 1},
  {title: '周结', type: 2},
  {title: '月结', type: 3},
  {title: '完工结', type: 4}
]

// 性别要求
const sexTypes = [
  // {title: '不限', type: -1},
  {title: '不限', type: 0},
  {title: '仅限男', type: 1},
  {title: '仅限女', type: 2},
  {title: '男生可报', type: -2},
  {title: '女生可报', type: -3}
]

// 工作周期
const workDateTypes = [
  {type: -1, title: '不限'},
  {type: 1001, title: '长期'},
  {type: 1002, title: '短期'},
]


export default {
  sortTypes,
  settleTypes,
  sexTypes,
  screenTypes,
  workDateTypes
}