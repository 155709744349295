<template>
  <div>
    <NavTitle class="mb16" :title="navTitle"/>
    <div class="container">
      <el-form ref="formRef" label-width="150px">
        <div class="panel">
          <PanelTitle class="mb24" title="常规项" :divided="true"/>
          <div class="content">
            <el-form-item :label="posterTitle" required>
              <!-- <el-radio-group v-model="uploadTypeForm.posterImg">
                <el-radio :label="0">上传图片</el-radio>
                <el-radio :label="1">手动输入</el-radio>
              </el-radio-group> -->
              <!-- <el-input v-if="uploadTypeForm.posterImg" class="mt16" type="text" placeholder="输入图片地址"></el-input> -->
              <UploadImage :imgUrl="commonForm.posterImg.url" :autoUpload="false" 
                :width="(resType == 'HOME_BUTTON' || resType == 'FIND_BUTTON' || resType == 'HOME_TYPE_TAB' || resType == 'FIND_TYPE_TAB') ? '150px':'300px'" 
                height="auto"
              @success="handleUploadSuccess($event, 'poster')"></UploadImage>
            </el-form-item>
            <el-form-item label="标题：" v-if="commonRequire.text.show" :required="commonRequire.text.require">
              <el-input type="text" placeholder="请输入标题" v-model="commonForm.text"></el-input>
            </el-form-item>
            <el-form-item label="子标题：" v-if="commonRequire.subText.show" :required="commonRequire.subText.require">
              <el-input type="text" placeholder="请输入子标题" v-model="commonForm.subText"></el-input>
            </el-form-item>
            <el-form-item label="角标题：" v-if="commonRequire.cornerMarkText.show" :required="commonRequire.cornerMarkText.require">
              <el-input type="text" placeholder="请输入角标题" v-model="commonForm.cornerMarkText"></el-input>
            </el-form-item>
            <el-form-item label="按钮图标：" v-if="commonRequire.btnIcon.show" :required="commonRequire.btnIcon.require">
              <UploadImage :imgUrl="commonForm.btnIcon.url" :autoUpload="false" @success="handleUploadSuccess($event, 'btnIcon')"></UploadImage>
            </el-form-item>
            <el-form-item label="头像列表：" v-if="commonRequire.avatars.show" :required="commonRequire.avatars.require">
              <div class="avatar-imgs">
                <div class="avatar-img" v-for="(item, index) in commonForm.avatars" :key="index">
                  <img class="btn-clear" src="@/assets/images/close.png" @click="deleteAvatar(index)">
                  <LoadImage :src="item.url" preview></LoadImage>
                </div>
                <UploadImage  :autoUpload="false" multiple @success="uploadAvatarSuccess"></UploadImage>
              </div>
            </el-form-item>
            <el-form-item label="选择点击海报动作：">
              <el-radio-group v-model="actionType">
                <el-radio :label="item.type" v-for="(item, index) in actionList" :key="index">{{item.text}}</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="页面类型：" v-if="actionType == 1">
              <el-radio-group class="mt16" v-model="indexType">
                <el-radio class="mb16 mr16" :label="item.type" v-for="(item, index) in indexList" :key="index">{{item.text}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- 跳转h5 -->
            <el-form-item label="h5链接地址：" v-if="actionType == 2 || actionType == 6" required>
              <el-input type="text" placeholder="请输入h5链接地址" v-model="h5Url"></el-input>
            </el-form-item>
          </div>
        </div>

        <div class="panel" v-if="actionType == 1 && (indexType == '10089' || indexType == '10094' || indexType == '10066' || indexType == '10067')">
          <!-- 职位详情页设置 -->
          <template v-if="indexType == '10067'">
            <PanelTitle class="mb24" title="职位详情页设置" :divided="true"/>
            <div class="content">
              <el-form-item label="职位ID：" required>
                <el-input placeholder="请输入职位ID" v-model="postForm.postId"></el-input>
              </el-form-item>
              <el-form-item label="轮转列表类型：">
                <el-radio-group class="mt16" v-model="postForm.listType">
                  <el-radio class="mb16 mr16" :label="lt.type" v-for="(lt, i) in postListTypes" :key="i" @click.native.prevent="postRotateTypeChange(lt)">{{lt.title}}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
          <!-- 列表页设置 -->
          <template v-else>
            <PanelTitle class="mb24" title="列表页设置" :divided="true"/>
            <div class="content">
              <el-form-item label="列表标题：">
                <el-input placeholder="请输入列表标题" v-model="listForm.title"></el-input>
              </el-form-item>
              <el-form-item label="列表页顶部背景图：" v-if="indexType != '10066'" required>
                <UploadImage :imgUrl="listForm.bgUrl.url" :autoUpload="false" width="300px" height="auto"
                @success="handleUploadSuccess($event, 'listTopImg')"></UploadImage>
              </el-form-item>
              <el-form-item label="顶部排序类型：" v-if="indexType == '10089'" required>
                <div v-for="(item, index) in curSortTabs" :key="index">
                  <el-radio-group :value="item.type" @input="topSortChange($event, index)">
                    <el-radio class="mb8" :label="st.type" v-for="(st, i) in sortTabsList" :key="i">{{st.title}}</el-radio>
                  </el-radio-group>
                  <el-input class="mb16" :placeholder="`请输入自定义排序${index+1}的名称`" v-model="item.title"></el-input>
                </div>
              </el-form-item>
              <el-form-item label="列表排序类型：" v-if="indexType == '10094' || indexType == '10066'" required>
                <el-radio-group class="mt16" v-model="listForm.sortCondition">
                  <el-radio class="mb16 mr16" :label="st.type" v-for="(st, i) in sortTabsList" :key="i">{{st.title}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="列表类型：" required>
                <el-radio-group class="mt16" v-model="listForm.listType">
                  <el-radio class="mb16 mr16" :label="lt.type" v-for="(lt, i) in postListTypes" :key="i">{{lt.title}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否需要定位：">
                <el-radio-group v-model="listForm.requireGps">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
          <!-- 列表类型配置 -->
          <template v-if="indexType != '10067' || (indexType == '10067' && postForm.listType.toString())">
            <PanelTitle class="mb24" title="列表类型配置（选填）" :divided="true"/>
            <div class="content">
              <el-form-item label="岗位类型：">
                <el-radio-group class="mt16" v-model="listForm.postCate.type">
                  <el-radio class="mb16 mr16" :label="0">包含配置的类目ID</el-radio>
                  <el-radio class="mb16 mr16" :label="1">排除配置的类目ID</el-radio>
                </el-radio-group>
                <!-- 选择职位分类 -->
                <div class="mt16" v-if="listForm.postCate.type === 1 || listForm.postCate.type === 0">
                  <el-cascader
                    style="width: 100%;"
                    v-model="listForm.postCate.list"
                    :options="postCategories.list"
                    :props="{ multiple: true, value: 'id', label: 'name', emitPath: false }"
                    :show-all-levels="false"
                    placeholder="请选择类目"
                    filterable
                    clearable>
                  </el-cascader>
                </div>
              </el-form-item>

              <el-form-item label="地区：">
                <el-select style="width: 100%;" v-model="listForm.city" placeholder="请选择/搜索目标城市" filterable clearable>
                  <el-option-group
                    v-for="group in cityList"
                    :key="group.label"
                    :label="group.provinceName">
                    <el-option
                      v-for="item in group.citys"
                      :key="item.cityName"
                      :label="item.cityName"
                      :value="item.cityName">
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <el-form-item label="结算方式：">
                <el-select v-model="listForm.settleType" placeholder="请选择结算方式" clearable>
                  <el-option
                    v-for="item in settleTypeList"
                    :key="item.type"
                    :label="item.title"
                    :value="item.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="性别要求：">
                <el-select v-model="listForm.sex" placeholder="请选择性别" clearable>
                  <el-option
                    v-for="item in sexTypeList"
                    :key="item.type"
                    :label="item.title"
                    :value="item.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="目标企业：">
                <el-select
                  style="width: 100%;"
                  v-model="listForm.enterprises"
                  multiple
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  placeholder="请选择目标企业"
                  :remote-method="getEnterpriseList">
                  <el-option
                    v-for="item in enterpriseList"
                    :key="item.entId"
                    :label="item.entName"
                    :value="item.entId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="岗位一级类目：">
                <el-radio-group class="mt16" v-model="listForm.postLevelOneCate.type">
                  <el-radio class="mb16 mr16" :label="0">包含配置的类目ID</el-radio>
                  <el-radio class="mb16 mr16" :label="1">排除配置的类目ID</el-radio>
                </el-radio-group>
                <div v-if="listForm.postLevelOneCate.type === 1 || listForm.postLevelOneCate.type === 0">
                  <el-select style="width: 100%;" v-model="listForm.postLevelOneCate.list" multiple placeholder="请选择类目">
                    <el-option
                      v-for="item in postCateTypes"
                      :key="item.categoryId"
                      :label="item.categoryName"
                      :value="item.categoryId">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
          </template>
          
        </div>
        
        <div class="panel" v-if="actionType == 1 && (indexType == '10089' || indexType == '10094' || indexType == '10066')">
          <!-- 推荐列表 -->
          <PanelTitle class="mb24" title="列表为空时，推荐的列表类型" :divided="true"/>
          <div class="content">
            <el-form-item label="列表类型：">
              <el-radio-group class="mt16" v-model="recomListForm.listType">
                <el-radio class="mb16 mr16" :label="lt.type" v-for="(lt, i) in postListTypes" :key="i" @click.native.prevent="recomListTypeChange(lt)">{{lt.title}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <!-- 推荐列表的配置 -->
          <!-- <PanelTitle class="mb24" title="列表类型配置（选填）" :divided="true"/>
          <div class="content">
            <el-form-item label="岗位类型：">
              <el-radio-group class="mt16" v-model="recomListForm.postCate.type">
                <el-radio class="mb16 mr16" :label="1">包含配置的类目ID</el-radio>
                <el-radio class="mb16 mr16" :label="2">排除配置的类目ID</el-radio>
              </el-radio-group>
              <div v-if="recomListForm.postCate.type === 1 || recomListForm.postCate.type === 0">
                <el-cascader
                  style="width: 100%;"
                  v-model="recomListForm.postCate.list"
                  :options="postCategories.list"
                  :props="{ multiple: true, value: 'data', label: 'name' }"
                  placeholder="请选择类目"
                  clearable>
                </el-cascader>
              </div>
            </el-form-item>

            <el-form-item label="地区：">
              <el-select style="width: 100%;" v-model="recomListForm.city" placeholder="请选择/搜索目标城市" filterable clearable>
                <el-option-group
                  v-for="group in cityList"
                  :key="group.label"
                  :label="group.provinceName">
                  <el-option
                    v-for="item in group.citys"
                    :key="item.cityName"
                    :label="item.cityName"
                    :value="item.cityName">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
            <el-form-item label="结算方式：">
              <el-select v-model="recomListForm.settleType" placeholder="请选择结算方式" clearable>
                <el-option
                  v-for="item in settleTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="性别要求：">
              <el-select v-model="recomListForm.sex" placeholder="请选择性别" clearable>
                <el-option
                  v-for="item in sexTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="目标企业：">
              <el-select
                style="width: 100%;"
                v-model="recomListForm.enterprises"
                multiple
                filterable
                clearable
                remote
                reserve-keyword
                placeholder="请选择目标企业"
                :remote-method="getEnterpriseList">
                <el-option
                  v-for="item in enterpriseList"
                  :key="item.entId"
                  :label="item.entName"
                  :value="item.entId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="岗位一级类目：">
              <el-radio-group class="mt16" v-model="recomListForm.postLevelOneCate.type">
                <el-radio class="mb16 mr16" :label="0">包含配置的类目ID</el-radio>
                <el-radio class="mb16 mr16" :label="1">排除配置的类目ID</el-radio>
              </el-radio-group>
              <div v-if="recomListForm.postLevelOneCate.type === 1 || recomListForm.postLevelOneCate.type === 0">
                <el-select style="width: 100%;" v-model="recomListForm.postLevelOneCate.list" multiple placeholder="请选择类目">
                  <el-option
                    v-for="item in postCateTypes"
                    :key="item.categoryId"
                    :label="item.categoryName"
                    :value="item.categoryId">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </div> -->
        </div>

        <!-- 企业专区 -->
        <template v-if=" actionType == 1 && indexType == '10098'">
          <div class="panel" v-for="(item, index) in zoneFormList" :key="index">
            <div class="flex between-center mb24">
              <PanelTitle title="企业专区" :divided="true"/>
              <img class="zone-delete" src="@/assets/images/icon-delete.png" @click="deleteZone(index)">
            </div>
            <div class="content">
              <el-form-item label="卡片描述设置：" required>
                <div class="example">
                  <img src="@/assets/images/example-enter.png">
                </div>
                <div class="label">企业logo</div>
                <UploadImage :imgUrl="item.entLogo.url" :autoUpload="false" @success="handleEntZoneUpload($event, 'entLogo', index)"></UploadImage>
                <div class="label">企业名称：</div>
                <el-input placeholder="请输入企业名称" v-model="item.entName"></el-input>
                <div class="label">二级文案描述（可以是报名人数或者其他）</div>
                <el-input placeholder="请输入描述" v-model="item.enrollDesc"></el-input>
              </el-form-item>
              <el-form-item label="企业海报：" required>
                <UploadImage :imgUrl="item.bannerPic.url" :autoUpload="false" width="300px" height="auto"
                @success="handleEntZoneUpload($event, 'bannerPic', index)"></UploadImage>
              </el-form-item>
              <el-form-item label="企业描述：" required>
                <el-input type="textarea" :rows="10" v-model="item.entDesc"
                placeholder="请输入工作内容与工作要求，如需要求职者完成什么工作，对求职者的要求是什么，薪酬福利说明等。"></el-input>
              </el-form-item>
              <el-form-item label="职位卡片的背景：" required>
                <UploadImage :imgUrl="item.postBgPic.url" :autoUpload="false" width="300px" height="auto"
                @success="handleEntZoneUpload($event, 'postBgPic', index)"></UploadImage>
              </el-form-item>
              <el-form-item label="目标企业：" required>
                <el-select
                  style="width: 100%;"
                  v-model="item.enterprises"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请选择/搜索目标企业"
                  :remote-method="getEnterpriseList">
                  <el-option
                    v-for="item in enterpriseList"
                    :key="item.entId"
                    :label="item.entName"
                    :value="item.entId">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="zdd-zone" @click="addEntZone">
            <img src="@/assets/images/add-fill.png">
            <span>添加新专区</span>
          </div>
        </template>
      </el-form>
      <div class="flex justify-center items-center">
        <el-button plain @click="$router.go(-1)">取消</el-button>
        <el-button type="primary" class="ml8" @click="handleSubmit">{{this.isEdit ? '保存':'添加'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import PanelTitle from '@/components/PanelTitle'
import UploadImage from '@/components/UploadImage'
import LoadImage from '@/components/LoadImage'
import { resParamsApi, resItemsManageApi, resItemsListApi } from '@/api/resConf.js'
import { categoriesApi } from '@/api/merchant.js'
import { entSearchApi } from '@/api/enterprise.js'
import { getParamsFromUrl } from '@/utils/util.js'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
import cityList from '@/libs/cityData.js'
import screenData from '@/types/screen.js'
export default {
  name: "PosterEdit",
  components: {
    NavTitle,
    PanelTitle,
    UploadImage,
    LoadImage
  },
  data() {
    return {
      resType: this.$route.query.resType,
      confId: this.$route.query.confId,
      isEdit: this.$route.query.isEdit == 'true',
      editIndex: this.$route.query.editIndex || 0,
      actionList: [
        {type: 0, text: '无操作'},
        {type: 1, text: '跳转应用内的页面'},
        {type: 2, text: '跳转h5链接'},
        {type: 6, text: 'native内打开H5'},
      ],
      indexList: [
        {type: '10067', text: '职位详情页'},
        {type: '10089', text: '带3个排序tap和顶部图片的列表页'},
        {type: '10094', text: '只带顶部图片的列表页'},
        {type: '10066', text: '纯净的列表页'},
        {type: '10098', text: '名企专区'}
      ],
      sortTabsList: screenData.sortTypes,
      postListTypes: [],

      actionType: 0,
      indexType: '10067',
      curSortTabs: [
        {type: '', title: ''},
        {type: '', title: ''},
        {type: '', title: ''},
      ],
      
      cityList,
      settleTypeList: screenData.settleTypes,
      sexTypeList: screenData.sexTypes,
      postCategories: {
        type: '', //1-兼职 2-实习 4-全职
        list: []
      },
      postCateTypes: [],
      enterpriseList: [],
      h5Url: '',
      commonForm: {
        posterImg: {
          file: null,
          url: '', // 显示的url
          uploadUrl: '', // 提交的url
        },
        text: '',
        subText: '',
        cornerMarkText: '',
        btnIcon: {
          file: null,
          url: '',
          uploadUrl: ''
        }, // 发现页卡片"去看看"图标
        avatars: [], // 发现页卡片"头像"
      },
      postForm: {
        postId: '',
        listType: ''
      },
      listForm: {
        title: '',
        bgUrl: {
          file: null,
          url: '',
          uploadUrl: ''
        },
        listType: 0, // 列表类型
        sortCondition: 0, // 列表排序类型（只带顶部图片的列表页）拼接在url里
        postCate: {
          type: '', //0包括、1排除
          list: [],
        }, // 岗位类型
        city: '',
        sex: '',
        settleType: '', //结算方式
        enterprises: [], //目标企业
        postLevelOneCate: {
          type: '', //包括、排除
          list: [],
        }, //岗位一级类目
        requireGps: false, // 定位提示
      },
      recomListForm: {
        listType: '',
        postCate: {
          type: '', //包括、排除
          list: [],
        }, // 岗位类型
        city: '',
        sex: '',
        settleType: '', //结算方式
        enterprises: [], //目标企业
        postLevelOneCate: {
          type: '', //包括、排除
          list: [],
        }, //岗位一级类目
      },
      zoneFormList: [],
      rules: {
        postId: [
          { required: true, message: '请输入职位ID', trigger: 'blur' },
        ]
      },
      configList: [],
      uploadTypeForm: {
        posterImg: 0, // 0：图片上传， 1：手动输入
        avatars: 0,
        bgUrl: 0
      }
    }
  },
  computed: {
    navTitle() {
      const map = {
        HOME_BANNER: '首页海报',
        HOME_BUTTON: '首页金刚区',
        HOME_TYPE_TAB: '首页分栏',
        HOME_NAV_PIC: '首页卡片',
        FIND_BANNER: '发现页海报',
        FIND_BUTTON: '发现页金刚区',
        FIND_TYPE_TAB: '发现页分栏',
        FIND_NAV: '发现页卡片'
      }
      return `${map[this.resType] || ''}配置`
    },
    posterTitle() {
      if(this.resType == 'HOME_BANNER' || this.resType == 'FIND_BANNER') {
        return '海报图片'
      }
      if(this.resType == 'HOME_BUTTON' || this.resType == 'FIND_BUTTON' || this.resType == 'HOME_TYPE_TAB' || this.resType == 'FIND_TYPE_TAB') {
        return '金刚区图标'
      }
      if(this.resType == 'HOME_NAV_PIC' || this.resType == 'FIND_NAV') {
        return '卡片图片'
      }
    },
    commonRequire() {
      const obj = {
        text: {
          show: false,
          require: false
        },
        subText: {
          show: false,
          require: false
        },
        cornerMarkText: {
          show: false,
          require: false
        },
        btnIcon: {
          show: false,
          require: false
        },
        avatars: {
          show: false,
          require: false
        }
      }
      if(this.resType == 'HOME_BUTTON' || this.resType == 'FIND_BUTTON' || this.resType == 'HOME_TYPE_TAB' || this.resType == 'FIND_TYPE_TAB') {
        obj.text.show = true
        obj.text.require = true
      }
      if(this.resType == 'HOME_NAV_PIC' || this.resType == 'FIND_NAV') {
        obj.text.show = true
        obj.text.require = true
        obj.subText.show = true
        obj.subText.require = false
        obj.btnIcon.show = true
        obj.btnIcon.require = false
        obj.avatars.show = true
        obj.avatars.require = false
      }
      if(this.resType == 'HOME_BUTTON' || this.resType == 'FIND_BUTTON' || this.resType == 'HOME_TYPE_TAB' || this.resType == 'FIND_TYPE_TAB') {
        obj.cornerMarkText.show = true
        obj.cornerMarkText.require = false
      }
      return obj
    }
  },
  created() {
    this.getPostListType()
    this.getConfigList()
    this.getPostCategories()
  },
  methods: {
    getConfigList() {
      if(this.resType == 'HOME_TYPE_TAB' || this.resType == 'FIND_TYPE_TAB') {
        try {
          const info = JSON.parse(sessionStorage.getItem('COLUMN_NAV_BUTTON_INFO'))
          if(this.isEdit && info) this.fillDetail(info)
        } catch (error) {}
      } else {
        const formData = {
          confId: this.confId,
          grayConfig: this.$store.state.isResourceGray,
          resType: this.resType
        }
        console.log('formData', formData)
        resItemsListApi(formData).then(res => {
          console.log('原列表', res)
          if(this.resType == 'HOME_BANNER' || this.resType == 'FIND_BANNER' || this.resType == 'HOME_NAV_PIC' || this.resType == 'FIND_NAV') {
            this.configList = res.data.banners || []
          }
          if(this.resType == 'HOME_BUTTON' || this.resType == 'FIND_BUTTON') {
            this.configList = res.data.navigates || []
          }
          if(this.isEdit) this.fillDetail(this.configList[this.editIndex])
        }).catch(err => {
          this.$tips({message: err, type: 'error'})
        })
      }
      
    },
    getPostListType() {
      resParamsApi().then(res => {
        console.log('资源配置可选参数', res)
        this.postListTypes = res.data.postListTypes || []
        this.postCateTypes = res.data.categoryTypes || []

      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    filterPostCate(list) {
      for (const item of list) {
        const map = {
          1: '兼职',
          2: '实习',
          4: '全职'
        }
        item.name = item.name + '(' + map[item.natureType] + ')'
        if(item.data) item.id = item.data.id
        if(item.children && item.children.length == 0) delete item.children
        if(item.children && item.children.length > 0) {
          this.filterPostCate(item.children)
        }
      }
      return list
    },
    async getPostCategories() {
      const res1 = await categoriesApi({ natureType: 1 })
      const res2 = await categoriesApi({ natureType: 2 })
      const res3 = await categoriesApi({ natureType: 4 })
      const list1 = this.filterPostCate(res1.data.categoryNodes)
      const list2 = this.filterPostCate(res2.data.categoryNodes)
      const list3 = this.filterPostCate(res3.data.categoryNodes)
      const list = [
        {id: '00001', name:'兼职', children: list1},
        {id: '00002', name:'实习', children: list2},
        {id: '00003', name:'全职', children: list3},
      ]
      console.log('职位分类数据', list)
      // this.listForm.postCate.list = []
      // const list = res.data.categoryNodes || []
      this.postCategories.list = list
    },
    fillDetail(detailInfo) {
      // 数据回显
      this.actionType = detailInfo.act
      this.commonForm.posterImg = {
        url: detailInfo.pic || '',
        uploadUrl: detailInfo.pic || '',
        file: null
      }
      this.commonForm.text = detailInfo.text || ''
      this.commonForm.subText = detailInfo.extInfo?.subText || ''
      this.commonForm.cornerMarkText = detailInfo.cornerMarkText || ''
      if(detailInfo.extInfo?.btnIcon) {
        ossKeyToUrl({key: detailInfo.extInfo?.btnIcon}).then(res => {
          this.commonForm.btnIcon.url = res.url
        })
      }

      // 头像列表
      if(this.commonRequire.avatars.show && detailInfo?.extInfo?.avatars?.length > 0) {
        for (const ossUrl of detailInfo.extInfo.avatars) {
          ossKeyToUrl({key: ossUrl}).then(res => {
            this.commonForm.avatars.push({
              ossUrl,
              url: res.url
            })
          })
        }
      }
      // h5链接
      if(this.actionType == 2 || this.actionType == 6) {
        this.h5Url = detailInfo.url
      }
      // 跳转应用内页面
      if(this.actionType == 1) {
        const urlParams = getParamsFromUrl(detailInfo.url)
        console.log('url参数', urlParams)
        this.indexType = urlParams.index
        if(this.indexType == '10067') {
          this.postForm.postId = urlParams.pId
          if(detailInfo.extInfo?.type || detailInfo.extInfo?.type === 0) {
            this.postForm.listType = Number(detailInfo.extInfo.type)
          }
        }

        if(this.indexType == '10089' || this.indexType == '10094' || this.indexType == '10066') {
          this.listForm.title = urlParams.title || ''
          this.listForm.listType = Number(urlParams.type)
          this.listForm.requireGps = detailInfo.extInfo?.requireGps || false
          if(detailInfo.extInfo?.emptyType || detailInfo.extInfo?.emptyType === 0) {
            this.recomListForm.listType = Number(detailInfo.extInfo.emptyType)
          }
        }

        // 顶部背景图
        if(this.indexType == '10089' || this.indexType == '10094') {
          this.listForm.bgUrl = {
            url: detailInfo.extInfo.bgUrl || '',
            uploadUrl: detailInfo.extInfo.bgUrl || '',
            file: null
          }
        }

        // 顶部排序tab
        if(this.indexType == '10089') {
          if(detailInfo.extInfo.sortTabs) {
            this.curSortTabs = JSON.parse(detailInfo.extInfo.sortTabs)
          }
        }

        // 列表排序类型
        if(this.indexType == '10094' || this.indexType == '10066') {
          this.listForm.sortCondition = Number(urlParams.sortCondition || 0)
        }

        // 列表可选项
        if((this.indexType == '10089' || this.listType == '10094' || this.indexType == '10066' || this.indexType == '10067') && detailInfo.extInfo.screens ) {
          const screens = JSON.parse(detailInfo.extInfo.screens)
          for (const info of screens) {
            if(info.screenType == 1) {
              this.listForm.postCate = {
                type: info.screenOption,
                list: info.categoryIds
              }
            }
            if(info.screenType == 2) {
              this.listForm.city = info.city
            }
            if(info.screenType == 3) {
              this.listForm.settleType = Number(info.screenOption)
            }
            if(info.screenType == 4) {
              this.listForm.sex = Number(info.screenOption)
            }
            if(info.screenType == 7) {
              this.listForm.enterprises = info.ids
            }
            if(info.screenType == 8) {
              this.listForm.postLevelOneCate = {
                type: info.screenOption,
                list: info.categoryIds
              }
            }
          }
        }

        // 名企专区
        if(this.indexType == '10098') {
          if(detailInfo.extInfo?.enterprises && detailInfo.extInfo.enterprises.length > 0) {
            for (const item of detailInfo.extInfo.enterprises) {
              let ids = []
              if(item.screens && typeof item.screens == 'string') {
                ids = JSON.parse(item.screens)[0].ids
              } else {
                ids = item.screens[0].ids
              }
              this.zoneFormList.push({
                entLogo: {
                  file: null,
                  url: item.entLogo || '',
                  uploadUrl: item.entLogo || ''
                },
                entName: item.entName || '',
                enrollDesc: item.enrollDesc || '',
                entDesc: item.entDesc || '',
                bannerPic: {
                  file: null,
                  url: item.bannerPic || '',
                  uploadUrl: item.bannerPic || ''
                },
                postBgPic: {
                  file: null,
                  url: item.postBgPic || '',
                  uploadUrl: item.postBgPic || ''
                },
                enterprises: ids
              })
            }
          }
        }
      }
    },
    getEnterpriseList(queryString) {
      if(!queryString.trim()) return
      entSearchApi({
        entName: queryString,
        pageSize: 30
      }).then(res => {
        console.log('企业搜索', res)
        this.enterpriseList = res.data.enterpriseInfos || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    topSortChange(e, index) {
      console.log(e, index)
      const target = this.sortTabsList.find(item => item.type == e)
      this.curSortTabs.forEach(item => {
        if(item.type == e) {
          item.type = ''
          item.title = ''
        }
      })
      this.curSortTabs[index].type = e
      this.curSortTabs[index].title = target.title
    },
    handleUploadSuccess(e, type) {
      console.log('图片上传', e)
      if(type == 'poster') {
        this.commonForm.posterImg = {
          file: e.file,
          url: e.base64Url,
          uploadUrl: ''
        }
      }
      if(type == 'listTopImg') {
        this.listForm.bgUrl = {
          file: e.file,
          url: e.base64Url,
          uploadUrl: ''
        }
      }
      if(type == 'btnIcon') {
        this.commonForm.btnIcon = {
          file: e.file,
          url: e.base64Url,
          uploadUrl: ''
        }
      }
    },
    handleEntZoneUpload(e, type, index) {
      if(type == 'entLogo') {
        this.zoneFormList[index].entLogo = {
          file: e.file,
          url: e.base64Url,
          uploadUrl: ''
        }
      }
      if(type == 'bannerPic') {
        this.zoneFormList[index].bannerPic = {
          file: e.file,
          url: e.base64Url,
          uploadUrl: ''
        }
      }
      if(type == 'postBgPic') {
        this.zoneFormList[index].postBgPic = {
          file: e.file,
          url: e.base64Url,
          uploadUrl: ''
        }
      }
    },
    uploadAvatarSuccess(list) {
      console.log('上传头像', list)
      for (const item of list) {
        this.commonForm.avatars.push({
          url: item.base64Url,
          file: item.file
        })
      }
    },
    deleteAvatar(index) {
      this.commonForm.avatars.splice(index, 1)
    },
    addEntZone() {
      this.zoneFormList.push({
        entLogo: {
          file: null,
          url: '',
          uploadUrl: ''
        },
        enrollDesc: '',
        entDesc: '',
        bannerPic: {
          file: null,
          url: '',
          uploadUrl: ''
        },
        postBgPic: {
          file: null,
          url: '',
          uploadUrl: ''
        },
        enterprises: ''
      })
    },
    deleteZone(index) {
      this.$confirm('确认删除该专区吗').then(action => {
        if(action == 'confirm') {
          this.zoneFormList.splice(index, 1)
        }
      })
    },
    async handleSubmit() {
      if(this.commonRequire.text.require && !this.commonForm.text.trim()) return this.$tips({message: '请输入标题', type:'warning'})
      if(this.commonRequire.subText.require && !this.commonForm.subText.trim()) return this.$tips({message: '请输入子标题', type:'warning'})
      if(this.commonRequire.cornerMarkText.require && !this.commonForm.cornerMarkText.trim()) return this.$tips({message: '请输入角标题', type:'warning'})
      if(this.commonRequire.avatars.require && !this.commonForm.avatars?.length) return this.$tips({message: '请上传头像列表', type:'warning'})
      const { uploadImageAsync } = await uploadImageUtil({directory: 'resource', isPublic: true})
      // 上传海报/金刚区图片
      let posterPic = ''
      if(this.commonForm.posterImg.file) {
        try {
          const res = await uploadImageAsync(this.commonForm.posterImg.file)
          posterPic = res.url
        } catch (err) {
          return this.$tips({message: '上传图片失败', type: 'error'})
        }
      } else if(this.commonForm.posterImg.uploadUrl) {
        posterPic = this.commonForm.posterImg.uploadUrl
      } else {
        return this.$tips({message: '请上传'+ this.posterTitle, type:'warning'})
      }

      // 上传按钮图标
      let btnIcon = ''
      if(this.commonRequire.btnIcon.show)  {
        if(this.commonForm.btnIcon.file) {
          try {
            const res = await uploadImageAsync(this.commonForm.btnIcon.file)
            btnIcon = res.url
          } catch (err) {
            return this.$tips({message: '上传图片失败', type: 'error'})
          }
        } else if(this.commonForm.btnIcon.uploadUrl) {
          btnIcon = this.commonForm.btnIcon.uploadUrl
        } else {
          if(this.commonRequire.btnIcon.require) return this.$tips({message: '请上传按钮图标', type:'warning'})
        }
      }
      
      const resJson = {
        act: this.actionType,
        pic: posterPic,
        text: this.commonForm.text,
        url: '',
        extInfo: {
          subText: this.commonForm.subText,
          btnIcon,
        },
        cornerMarkText: this.commonForm.cornerMarkText,
      }
      // 跳转h5
      if(this.actionType == 2 || this.actionType == 6) {
        resJson.url = this.h5Url
      }
      // 跳转应用内页面
      if(this.actionType == 1) {
        let url = `ocean://page?module=ocean&index=${this.indexType}`
        const extInfo = {}
        if(this.indexType == '10067') {
          if(!this.postForm.postId.toString().trim()) return this.$tips({message: '请输入职位ID', type:'warning'})
          url += `&pId=${this.postForm.postId}`
          // 职位轮转列表类型
          extInfo.type = this.postForm.listType
        }

        // 上传顶部背景图
        let bgUrl = ''
        if( this.indexType == '10089' || this.indexType == '10094') {
          if(this.listForm.bgUrl.file) {
            try {
              const res = await uploadImageAsync(this.listForm.bgUrl.file)
              bgUrl = res.url
            } catch (err) {
              return this.$tips({message: '上传顶部背景图失败', type: 'error'})
            }
          } else if(this.listForm.bgUrl.uploadUrl) {
            bgUrl = this.listForm.bgUrl.uploadUrl
          } else {
            return this.$tips({message: '请上传顶部背景图', type:'warning'})
          }
          extInfo.bgUrl = bgUrl
        }

        if(this.indexType == '10089') {
          if(this.listForm.title.trim()) url += `&title=${this.listForm.title.trim()}`
          for (const item of this.curSortTabs) {
            if((!item.type && item.type !== 0) || !item.title ) {
              return this.$tips({message: '请检查顶部排序类型是否选择/填写完整', type:'warning'})
            }
          }
          url += `&type=${this.listForm.listType}`
          extInfo.sortTabs = JSON.stringify(this.curSortTabs)
        }

        if(this.indexType == '10094' || this.indexType == '10066') {
          if(this.listForm.title.trim()) url += `&title=${this.listForm.title.trim()}`
          url += `&type=${this.listForm.listType}&sortCondition=${this.listForm.sortCondition}`
        }
        
        // 推荐列表类型、是否需要定位
        if(this.indexType == '10089' || this.indexType == '10094' || this.indexType == '10066') {
          if(this.recomListForm.listType || this.recomListForm.listType === 0) {
            resJson.extInfo.emptyType = this.recomListForm.listType
          }
          resJson.extInfo.requireGps = this.listForm.requireGps
        }
        
        // 列表可选项(筛选项)
        if(this.indexType == '10089' || this.indexType == '10094' || this.indexType == '10066' || this.indexType == '10067') {
          const screens = []
          if((this.listForm.postCate.type === 1 || this.listForm.postCate.type === 0) && this.listForm.postCate.list?.length > 0) {
            screens.push({
              screenType: 1,
              screenOption: this.listForm.postCate.type,
              categoryIds: this.listForm.postCate.list
            })
          }
          if(this.listForm.city) {
            screens.push({
              screenType: 2,
              city: this.listForm.city
            })
          }
          if(this.listForm.settleType || this.listForm.settleType === 0) {
            screens.push({
              screenType: 3,
              screenOption: this.listForm.settleType
            })
          }
          if(this.listForm.sex || this.listForm.sex === 0) {
            screens.push({
              screenType: 4,
              screenOption: this.listForm.sex
            })
          }
          if(this.listForm.enterprises?.length > 0) {
            screens.push({
              screenType: 7,
              ids: this.listForm.enterprises
            })
          }
          if((this.listForm.postLevelOneCate.type === 1 || this.listForm.postLevelOneCate.type === 0) 
          && this.listForm.postLevelOneCate.list.length > 0){
            screens.push({
              screenType: 8,
              screenOption: this.listForm.postLevelOneCate.type,
              categoryIds: this.listForm.postLevelOneCate.list
            })
          }
          if(this.indexType == '10067') {
            if(this.postForm.listType.toString()) extInfo.screens = JSON.stringify(screens)
          } else {
            extInfo.screens = JSON.stringify(screens)
          }
        }

        // 企业专区
        if(this.indexType == '10098') {
          url += `&type=0`
          extInfo.enterprises = []
          for (const zform of this.zoneFormList) {
            // 上传企业logo
            let entLogo = ''
            if(zform.entLogo.file) {
              try {
                const res = await uploadImageAsync(zform.entLogo.file)
                entLogo = res.url
              } catch (err) {
                return this.$tips({message: '上传企业logo失败', type: 'error'})
              }
            } else if(zform.entLogo.uploadUrl) {
              entLogo = zform.entLogo.uploadUrl
            } else {
              return this.$tips({message: '请上传企业logo', type:'warning'})
            }
            // 上传企业海报
            let bannerPic = ''
            if(zform.bannerPic.file) {
              try {
                const res = await uploadImageAsync(zform.bannerPic.file)
                bannerPic = res.url
              } catch (err) {
                return this.$tips({message: '上传企业海报失败', type: 'error'})
              }
            } else if(zform.bannerPic.uploadUrl) {
              bannerPic = zform.bannerPic.uploadUrl
            } else {
              return this.$tips({message: '请上传企业海报', type:'warning'})
            }
            // 上传职位卡片背景
            let postBgPic = ''
            if(zform.postBgPic.file) {
              try {
                const res = await uploadImageAsync(zform.postBgPic.file)
                postBgPic = res.url
              } catch (err) {
                return this.$tips({message: '上传职位卡片背景图失败', type: 'error'})
              }
            } else if(zform.postBgPic.uploadUrl) {
              postBgPic = zform.postBgPic.uploadUrl
            } else {
              this.$tips({message: '请上传职位卡片背景图', type:'warning'})
            }
            
            if(!zform.entName) return this.$tips({message: '请填写企业名称', type:'warning'})
            if(!zform.enrollDesc.trim()) return this.$tips({message: '请填写二级文案描述', type:'warning'})
            if(!zform.entDesc.trim()) return this.$tips({message: '请填写企业描述', type:'warning'})
            if(!zform.enterprises?.length) return this.$tips({message: '请选择目标企业', type:'warning'})
            const screens = [{
              screenType: 7,
              ids: zform.enterprises
            }]
            const obj = {
              entLogo,
              bannerPic,
              postBgPic,
              entName: zform.entName,
              enrollDesc: zform.enrollDesc.trim(),
              entDesc: zform.entDesc.trim(),
              screens: JSON.stringify(screens)
            }
            extInfo.enterprises.push(obj)
          }
          
        }
        resJson.url = url
        Object.assign(resJson.extInfo, extInfo)
      }
       // 上传头像列表
      if(this.commonRequire.avatars.show) {
        const avatarArr = await this.uploadAvatars()
        if(avatarArr?.length > 0) {
          resJson.extInfo.avatars = avatarArr
        }
      }
      
      console.log('resJson', resJson)
      // return
      if(this.resType == 'HOME_TYPE_TAB' || this.resType == 'FIND_TYPE_TAB') {
        // formData.tabs = this.columnConfigList
        this.$eventBus.$emit('ColumnNavButtonChange', {
          isEdit: this.isEdit,
          editIndex: this.editIndex,
          data: resJson
        })
        this.$router.go(-1)
      } else {
        if(this.isEdit) {
          this.configList.splice(this.editIndex, 1, resJson)
          console.log('configList', this.configList)
          // return
        } else {
          this.configList.push(resJson)
        }
        const formData = {
          resType: this.resType,
          confId: this.confId,
          grayConfig: this.$store.state.isResourceGray,
        }
        if(this.resType == 'HOME_BANNER' || this.resType == 'FIND_BANNER' || this.resType == 'HOME_NAV_PIC' || this.resType == 'FIND_NAV') {
          formData.banners = this.configList
        }
        if(this.resType == 'HOME_BUTTON' || this.resType == 'FIND_BUTTON') {
          formData.navigates = this.configList
        }
        console.log('formData', formData)
        resItemsManageApi(formData).then(res => {
          this.$tips({message:res.msg, type:'success'})
          this.$router.go(-1)
        }).catch(err => {
          this.$tips({message:err, type:'error'})
        })
      }
      
    },
    uploadAvatars() {
      return new Promise(async (resolve, reject) => {
        if(this.commonForm.avatars?.length > 0) {
          const { uploadImageAsync } = await uploadImageUtil({directory: 'resource'})
          const list = []
          for (const item of this.commonForm.avatars) {
            if(item.ossUrl) {
              list.push(item.ossUrl)
              if(list.length == this.commonForm.avatars.length) resolve(list)
            } else if(item.file) {
              uploadImageAsync(item.file).then(res => {
                console.log('上传成功', res)
                list.push(res.ossUrl)
                if(list.length == this.commonForm.avatars.length) resolve(list)
              }).catch(err => {
                this.$tips({message: '上传图片失败', type: 'error'})
                if(list.length == this.commonForm.avatars.length) resolve(list)
              })
            }
          }
        } else {
          resolve([])
        }
      })
    },
    recomListTypeChange(e) {
      this.recomListForm.listType = this.recomListForm.listType === e.type ? '' : e.type
    },
    postRotateTypeChange(e) {
      this.postForm.listType = this.postForm.listType === e.type ? '' : e.type
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 16px;
}
.panel {
  background-color: #fff;
  padding-bottom: 1px;
  margin-bottom: 24px;
  .content {
    width: 800px;
    margin: 0 auto;
  }
}
.zone-delete {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.zdd-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  background-color: #fff;
  margin-bottom: 24px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }
}

.avatar-imgs {
  display: flex;
  flex-wrap: wrap;
  .avatar-img {
    position: relative;
    margin: 0 4px 4px 0;
    width: 104px;
    height: 104px;
  }
  .btn-clear {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
  }
}
</style>