import request from '@/utils/request'

/**
  * 模糊查询系统中企业列表
  */
export function entSearchApi(data = {}) {
  return request({
    url: 'enterprise/search',
    method: 'POST',
    data
  })
}

/**
  * 企业信息接口
  */
export function enterpriseInfoApi(data = {}) {
  return request({
    url: 'enterprise/enterpriseInfo',
    method: 'POST',
    data
  })
}