import request from '@/utils/request'

/**
  * 职位类型选择接口
  */
export function categoriesApi(data = {}) {
  return request({
    url: 'merchant/categories',
    method: 'POST',
    data
  })
}

/**
  * 用户的简历
  */
export function resumeDetailApi(data = {}) {
  return request({
    url: 'merchant/resumeDetail',
    method: 'POST',
    data
  })
}